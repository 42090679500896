@import url('https://fonts.googleapis.com/css?family=Merriweather');

$font-heading: 'Merriweather', 'serif';

body {
  margin: 0;
  padding: 0;
  font-family: $font-heading;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
    color: rgb(204, 109, 109);
}

a{
  color: rgb(6, 94, 153);
  text-decoration: none;
}