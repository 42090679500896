.welcome{
  font-size: 1.4em;
  line-height: 1.9em;
  max-width: 750px;
  text-align: left;
  color: rgba(0,0,0,0.7);
  padding: 0 15px;
  text-align: center;

  @media (min-width: 1000px){
    font-size: 1.6em;
    line-height: 1.9em;
  }
  
  @media (min-width: 1400px){
    font-size: 1.85em;
    line-height: 1.9em;
    max-width: 930px;
  }

  .links{
    text-align: center;
  }
  a{
    color: rgba(0,0,0,0.7);
    margin-left: 10px;
    margin-right: 10px;
  }
}