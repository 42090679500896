@import url(https://fonts.googleapis.com/css?family=Merriweather);
body {
  margin: 0;
  padding: 0;
  font-family: "Merriweather", "serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  color: #cc6d6d; }

a {
  color: #065e99;
  text-decoration: none; }

@media screen and (min-width: 750px) {
  .App {
    display: -webkit-flex;
    display: flex;
    height: 100vh;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; } }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.welcome {
  font-size: 1.4em;
  line-height: 1.9em;
  max-width: 750px;
  text-align: left;
  color: rgba(0, 0, 0, 0.7);
  padding: 0 15px;
  text-align: center; }
  @media (min-width: 1000px) {
    .welcome {
      font-size: 1.6em;
      line-height: 1.9em; } }
  @media (min-width: 1400px) {
    .welcome {
      font-size: 1.85em;
      line-height: 1.9em;
      max-width: 930px; } }
  .welcome .links {
    text-align: center; }
  .welcome a {
    color: rgba(0, 0, 0, 0.7);
    margin-left: 10px;
    margin-right: 10px; }

